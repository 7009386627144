import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SitemapContent from '../components/Sitemap/Sitemap'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { SITEMAP } from '../components/internal-links'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'User Sitemap',
    url: SITEMAP,
  },
]

const Sitemap: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
      showPokerForm
      showRummyForm
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="Mega | Sitemap"
        description="Easily navigate the Mega website with our user-friendly sitemap. Discover all the features, pages and information Mega has to offer in one convenient location."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={breadcrumbs}
      />
      <SitemapContent />
    </Layout>
  )
}

export default Sitemap
