import { HelpCenterLink } from '../Footer/HelpCenter/HelpCenter'
import * as l from '../internal-links'

const links: { [index: string]: HelpCenterLink[] } = {
  Home: [
    {
      name: 'About Us',
      link: l.ABOUT_US,
    },
    {
      name: 'Responsible Gaming',
      link: l.RESPONSIBLE_GAMING,
    },
    {
      name: 'Certifications',
      link: l.CERTIFICATIONS,
    },
    {
      name: 'Privacy Policy',
      link: l.PRIVACY_POLICY,
    },
    {
      name: 'Testimonials',
      link: l.TESTIMONIALS,
    },
    {
      name: 'Terms and Conditions',
      link: l.TERMS,
    },
    {
      name: 'Gangs of Gamers',
      link: l.GANGS_OF_GAMERS,
    },
    {
      name: 'Download App',
      link: l.DOWNLOAD,
    },
  ],

  'Game Categories': [
    {
      name: 'All Categories',
      link: l.GAMES,
    },
    {
      name: 'Cards',
      link: l.CARDS,
    },
    {
      name: 'Download App',
      link: l.DOWNLOAD,
    },
  ],

  Games: [
    {
      name: 'Poker',
      link: l.POKER,
    },
    {
      name: 'Rummy',
      link: l.RUMMY,
    },
    {
      name: 'Download App',
      link: l.DOWNLOAD,
    },
  ],

  Support: [
    {
      name: 'FAQ',
      link: l.FAQS,
    },
    {
      name: 'Poker FAQ',
      link: l.POKER_FAQS,
    },
    
    {
      name: 'Contact Us',
      link: l.CONTACT_US,
    },
    {
      name: 'Help Center',
      link: l.HELP_CENTRE,
    },
    {
      name: 'Getting Started',
      link: l.HC_GETTING_STARTED,
    },
    {
      name: 'KYC and Payments',
      link: l.HC_KYC,
    },
    {
      name: 'Withdrawals',
      link: l.HC_PAYMENTS_AND_WITHDRAWALS,
    },
  ],
}

export default links
